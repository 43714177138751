import { Watermark } from 'antd';
import React, { useEffect, useState, useTransition } from 'react';
import { StaticImage } from './utils/Images'
import { Colors } from './utils/Colors';
import CustomButton from './components/CustomButton';
import Tags from 'components/Tags';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        const errorHandler = (error, info) => {
            console.error('Error:', error, info);
            setHasError(true);
        };
        window.addEventListener('error', errorHandler);
        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    return hasError ? (
        <Watermark style={{ background: Colors.BgTheme }} className='min-h-screen flex justify-center items-center' height={95} width={270} image={StaticImage.logo.whiteLogo}>
            <div className='text-center rounded p-5 bg-slate-800' style={{ opacity: 0.8, zIndex: 99 }}>
                <Tags className='text-white titleSmall' >Something went wrong.</Tags>
                <CustomButton
                    type='text'
                    size='large'
                    onClick={() => { startTransition(() => { window.location.reload() }) }}
                    loading={isPending}
                    className={`bg-primaryButtonColor hover:bg-pink-600 text-white w-100 mt-4`}
                >
                    {`Request Reload!`}
                </CustomButton>
            </div>
        </Watermark>
    ) : (
        children
    );
};

export default ErrorBoundary;
